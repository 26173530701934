<!--已审核待支付-->
<template>
  <div>
    <div class="red-box">
      <p>请在3个工作日内完成支付，超时订单将取消</p>
    </div>
    <div class="page-box">
      <div class="card-box flex-box" @click="selectAddress">
        <div>
          <div class="title">
            <img class="address" src="../../../assets/img/add_icon.png" alt="">
            选择配送方式
          </div>
          <!--        <div class="radio-group mt40">-->
          <!--          <div class="radio-btn active" @click="selectAddress">-->
          <!--            物流配送-->
          <!--          </div>-->
          <!--          <div class="radio-btn">-->
          <!--            到院取药-->
          <!--          </div>-->
          <!--          <div class="radio-btn">-->
          <!--            就近取药-->
          <!--          </div>-->
          <!--        </div>-->
          <div class="mt20 add_address">
            <div v-if="!address" class="text">
              <img class="add" src="../../../assets/img/more_icon.png" alt="">
              暂无地址/收货人信息，点击添加
            </div>
            <div v-else>
              {{ address.dpmc }}
              <span v-if="address.dcmc != address.dpmc">{{ address.dcmc }}</span>
              {{ address.ddmc }} {{ address.xxdz }}
            </div>
          </div>
        </div>
        <div>
          <van-icon name="arrow" color="#3D3D3D" size="20px" />
        </div>
      </div>
      <div class="card-box">
        <div class="title">
          在线药房
        </div>
        <drugItem :data="detailList" />
      </div>
      <div class="card-box">
        <div class="cell">
          <div class="cell_title">
            药品金额
          </div>
          <div class="cell_value middle-color">
            ¥ {{ details.ypje }}
          </div>
        </div>
        <div class="cell">
          <div class="cell_title">
            实付款
          </div>
          <div class="cell_value red-color">
            ¥ {{ details.sfk }}
          </div>
        </div>
      </div>
    </div>
    <div class="footers">
      <div class="message">
        {{ text }}
      </div>
      <div class="pay">
        <van-button type="info" round size="small" :loading="loading" class="footer-btn" @click="toPay(details)">
          立即支付
        </van-button>
        <div class="footer-text">
          合计：
          <span class="red-color">¥ {{ details.sfk }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { userModel } from '@/api/user'
import {appointModel} from '@/api/appoint'
import {CONSTANT} from '@/utils/constant'
import { getItem } from '@/utils/cache'

export default {
  data() {
    return {
      checked: true,
      text: '由于药品的特殊性，如非错发，漏发药品的情况下，药品一经发出不得退换，请核对药品信息无误后下单。',
      detailList: [],
      details: {},
      address: {
        dpmc: '',
        ddmc: '',
        dcmc: ''
      },
      orderId: -1,
      loading: false
    }
  },
  mounted() {
    this.orderId = this.$route.query.id
    this.address = getItem('address', {})
    this.queryCost()
  },
  methods: {
    queryCost() {
      userModel.preCostDetail(this.orderId).then(res => {
        this.detailList = res.data.details
        this.details = res.data
      })
    },
    selectAddress() {
      this.$router.push('/address?flag=cf')
    },
    validator() {
      if (!this.address.id) {
        this.$toast({
          message: '请选择地址!'
        })
        return false
      }

      return true
    },
    toPay(details) {
      if (this.validator()) {
        // 50 挂号 51 处方
        this.loading = true
        appointModel.checkstandCreate({lb:51, orderCode: details.cfddbm, addressId: this.address.id, psfs: 1,source:'1'}).then(res => {
          if(res.code==CONSTANT.SUCCESS_CODE){
            location.href = res.data.webURL
          }
        }).catch(error=>{
          this.$toast(error.msg)
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }

}
</script>
<style scoped lang='less'>
@import "../../../assets/styles/variable.less";

.red-box {
  width: 100%;
  height: 168px;
  background: #DC3030;

  p {
    color: #ffffff;
    margin: 0;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 88px;
  }
}

.page-box {
  padding: 0 32px 50px;
  box-sizing: border-box;
  position: relative;
  top: -82px;

  .flex-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .card-box {
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    padding: 40px 32px;
    margin-bottom: 24px;
    box-sizing: border-box;
    z-index: 2;

    .title {
      display: flex;
      align-items: center;
      font-size: 32px;
      font-weight: bold;
      color: @dark-font-color;

      .address {
        width: 37px;
        height: 37px;
        margin-right: 5px;
      }
    }

    .add_address {
      display: flex;
      align-items: center;

      .text {
        color: #3A8AE5;
        font-size: 28px;

        img {
          width: 30px;
          height: 30px;
          margin-right: 12px;
        }
      }

    }

    .radio-group {
      display: flex;
      justify-content: space-between;

      .radio-btn {
        width: 176px;
        height: 48px;
        line-height: 48px;
        color: #999999;
        text-align: center;
        border-radius: 24px;
        border: 1px solid #999999;

        &.active {
          color: @theme-color;
          border: 1px solid @theme-color;
        }
      }
    }

    .cell {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 16px 0;
      font-weight: 400;
      overflow: hidden;
      color: @dark-font-color;
      font-size: 28px;
      line-height: 40px;
    }

    .cell_title, .cell_value {
      flex: 1 1 auto;
    }

    .cell_value {
      overflow: hidden;
      text-align: right;
    }
  }

  .icon {
    position: relative;
    top: 8px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 98px;
  background: #FFFFFF;
  text-align: right;
  padding: 16px 32px;
  box-sizing: border-box;

  .footer-text {
    line-height: 68px;
    margin-right: 20px;
  }

  .footer-btn {
    width: 182px;
    font-size: 28px;
  }
}

.fl-right {
  float: right;
  margin-top: 6px;
}
.footers {
  position: fixed;
  bottom: 0;
  //display: flex;
  //flex-direction: row-reverse;
  width: 100%;
  height: 198px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 32px;
  box-sizing: border-box;

  .message {
    line-height: 1.7;
    color: #F83A1B;
  }

  .pay {
    display: flex;
    flex-direction: row-reverse;

    .footer-text {
      line-height: 68px;
      margin-right: 20px;
    }

    .footer-btn {
      width: 182px;
      font-size: 28px;
    }
  }

}
</style>
